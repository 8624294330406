import { Grid } from '@mui/material';
import { TotalRevenueByMonth } from './TotalRevenueByMonth';
import { TotalRevenueByChannel } from './TotalRevenueByChannel';
import { TotalRevenueWithReturn } from './TotalRevenueWithReturn';
import { TotalRevenueEquipment } from './TotalRevenueEquipment';
import { RevenueEquipmentByChannel } from './RevenueEquipmentByChannel';
import { RevenueByChannelAndMonth } from './RevenueByChannelAndMonth';
import { RevenueByChannelAndValue } from './RevenueByChannelAndValue';

export const ReportPage = () => {
  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} md={4}>
          <TotalRevenueByMonth />
        </Grid>
        <Grid item xs={12} md={4}>
          <TotalRevenueByChannel />
        </Grid>
        <Grid item xs={12} md={4}>
          <TotalRevenueWithReturn />
        </Grid>
        <Grid item xs={12} md={12}>
          <RevenueByChannelAndMonth />
        </Grid>
        <Grid item xs={12} md={6}>
          <RevenueByChannelAndValue />
        </Grid>
        <Grid item xs={12} md={6}>
          <RevenueEquipmentByChannel />
        </Grid>
        <Grid item xs={12} md={12}>
          <TotalRevenueEquipment />
        </Grid>
      </Grid>
    </>
  );
};
