import { Typography } from '@mui/material';
import ReactEcharts from 'echarts-for-react';
import { StyledCard } from '../styles';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { getVerboseNameFromDate, toTitleCase } from '../../../utils/utils';
import { ReportTotalRevenueEquipmentService } from '../../../services/Reports.service';
import { showSpinner, hideSpinner } from '../../../store/slicers/globalSpinner.slicer';
import { showSnackbarAlert } from '../../../store/slicers/snackbarAlert.slicer';
import { formatValue } from '../utils';

export const TotalRevenueEquipment = () => {
  const [data, setData] = useState<any[]>([]);
  const dispatch = useDispatch();

  const options = {
    title: {
      text: 'Quantidade de Equipamentos Faturados',
      subtext: `Total: ${formatValue(data.reduce((acc, curr) => acc + curr.value, 0))}`,
      left: 'center'
    },
    tooltip: {
      trigger: 'axis',
      axisPointer: { type: 'shadow' }
    },
    xAxis: {
      type: 'category',
      data: data.map((item) => toTitleCase(getVerboseNameFromDate(item.month))),
    },
    yAxis: {
      type: 'value'
    },
    series: [
      {
        data: data.map((item) => item.value),
        type: 'bar',
        itemStyle: {
          color: '#96DAE4'
        },
        label: {
          show: true,
          position: 'top',
        }
      }
    ]
  };

  const fetchReport = async () => {
    dispatch(showSpinner());
    try {
      const response = await new ReportTotalRevenueEquipmentService().listAll();
      if (response?.data?.results) {
        setData(response.data.results.report);
      }
    } catch (error: any) {
      const errorMessage = error?.data?.detail || 'Erro ao buscar dados do Gráfico';
      dispatch(
        showSnackbarAlert({
          title: 'Erro!',
          message: errorMessage,
          severity: 'error'
        })
      );
    } finally {
      dispatch(hideSpinner());
    }
  };

  useEffect(() => {
    fetchReport();
  }, []);

  return (
    <StyledCard>
      {data.length === 0 ? (
        <Typography variant="body1" align="center">
          Nenhum dado encontrado.
        </Typography>
      ) : (
        <ReactEcharts option={options} style={{height: 400}}/>
      )}
    </StyledCard>
  );
};
