import { Typography } from '@mui/material';
import ReactEcharts from 'echarts-for-react';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { breakWords, formatValue, formatValueToBRL, sumAndFormatValuesToBRL } from '../utils';
import { StyledCard } from '../styles';
import { hideSpinner, showSpinner } from '../../../store/slicers/globalSpinner.slicer';
import { ReportRevenueByChannelAndMonthService, ReportRevenueByChannelService } from '../../../services/Reports.service';
import { showSnackbarAlert } from '../../../store/slicers/snackbarAlert.slicer';
import { getVerboseNameFromDate, toTitleCase } from '../../../utils/utils';

const convertToDataset = (data: any[]): (string | number)[][] => {
  const channels = Array.from(new Set(data.map((item) => item.channel)));
  const months = Array.from(new Set(data.map((item) => item.month)));
  const dataset: (string | number)[][] = [['channels', ...channels]];

  months.forEach((month) => {
    const row: (string | number)[] = [toTitleCase(getVerboseNameFromDate(month))];
    channels.forEach((channel) => {
      const entry = data.find((item) => item.month === month && item.channel === channel);
      row.push(entry ? entry.value : 0);
    });
    dataset.push(row);
  });

  return dataset;
};

export const RevenueByChannelAndMonth = () => {
  const [data, setData] = useState<any[]>([]);
  const dispatch = useDispatch();

  const options = {
    grid: {
      left: '15%',
      right: '15%'
    },
    title: {
      text: 'Faturamento Líquido Por Canal (-Devolução)',
      subtext: `Total: ${sumAndFormatValuesToBRL(data)}`,
      left: 'center'
    },
    dataZoom: [
      {
        type: 'inside'
      },
      {
        type: 'slider',
        show: true,
        bottom: 0
      }
    ],
    legend: {
      orient: 'vertical',
      left: 'left',
      formatter: breakWords
    },
    toolbox: {
      show: true,
      feature: {
        mark: { show: true },
        magicType: {
          type: ['stack', 'line', 'bar'],
          title: {
            stack: 'Pilha ',
            line: 'Linhas',
            bar: 'Barras'
          }
        },
        restore: { show: false, title: 'Restaurar' }
      },
      itemSize: 20
    },
    tooltip: {
      valueFormatter: formatValueToBRL
    },
    xAxis: {
      type: 'category'
    },
    yAxis: {},
    dataset: {
      source: convertToDataset(data)
    },
    series: Array.from(new Set(data.map((item) => item.channel))).map(() => ({ type: 'bar' }))
  };

  const fetchReport = async () => {
    dispatch(showSpinner());
    try {
      const response = await new ReportRevenueByChannelAndMonthService().listAll();
      if (response?.data?.results) {
        setData(response.data.results.report);
      }
    } catch (error: any) {
      const errorMessage = error?.data?.detail || 'Erro ao buscar dados do Gráfico';
      dispatch(
        showSnackbarAlert({
          title: 'Erro!',
          message: errorMessage,
          severity: 'error'
        })
      );
    } finally {
      dispatch(hideSpinner());
    }
  };

  useEffect(() => {
    fetchReport();
  }, []);

  return (
    <StyledCard>
      {data.length === 0 ? (
        <Typography variant="body1" align="center">
          Nenhum dado encontrado.
        </Typography>
      ) : (
        <ReactEcharts option={options} style={{ height: 500 }} />
      )}
    </StyledCard>
  );
};
