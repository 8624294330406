import { AccessRoutes } from './access';
import { DashboardRoutes } from './dashboard';
import { FinanceRoutes } from './finance';
import { IntegrationRoutes } from './integration';
import { ReportRoutes } from './report';
import { SettingsRoutes } from './settings';

// ==============================|| MENU ITEMS ||============================== //

export const MenuItems = {
  items: [DashboardRoutes, AccessRoutes, FinanceRoutes, IntegrationRoutes, SettingsRoutes, ReportRoutes]
};
