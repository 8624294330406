import { Typography } from '@mui/material';
import ReactEcharts from 'echarts-for-react';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { ReportEquipmentByChannelService, ReportRevenueByChannelAndValueService } from '../../../services/Reports.service';
import { hideSpinner, showSpinner } from '../../../store/slicers/globalSpinner.slicer';
import { showSnackbarAlert } from '../../../store/slicers/snackbarAlert.slicer';
import { StyledCard } from '../styles';
import { breakWords, formatValueToBRL } from '../utils';

export const RevenueByChannelAndValue = () => {
  const [data, setData] = useState<any[]>([]);
  const dispatch = useDispatch();

  const options = {
    title: {
      text: 'Representatividade de Equipamentos Faturados / Canal - Valor',
      left: 'center',
    },
    tooltip: {
      trigger: 'item',
      axisPointer: { type: 'shadow' },
      formatter: function (params:any) {
        return `${params.name}<br/>Valor: <b>${formatValueToBRL(params.data.value)}</b> <br/> Percentual: <b>${params.percent}%</b>`;
    }
    },
    series: [
      {
        name: 'Canal',
        type: 'pie',
        padding: 10,
        data: data.map((item) => {
          return { value: item.value  , name: breakWords(item.channel) };
        }),
        emphasis: {
          itemStyle: {
            shadowBlur: 10,
            shadowOffsetX: 0,
            shadowColor: 'rgba(0, 0, 0, 0.5)'
          }
        }
      }
    ]
  };

  const fetchReport = async () => {
    dispatch(showSpinner());
    try {
      const response = await new ReportRevenueByChannelAndValueService().listAll();
      if (response?.data?.results) {
        setData(response.data.results.report);
      }
    } catch (error: any) {
      const errorMessage = error?.data?.detail || 'Erro ao buscar dados do Gráfico';
      dispatch(
        showSnackbarAlert({
          title: 'Erro!',
          message: errorMessage,
          severity: 'error'
        })
      );
    } finally {
      dispatch(hideSpinner());
    }
  };

  useEffect(() => {
    fetchReport();
  }, []);

  return (
    <StyledCard>
      {data.length === 0 ? (
        <Typography variant="body1" align="center">
          Nenhum dado encontrado.
        </Typography>
      ) : (
        <ReactEcharts option={options} style={{height: 400}}/>
      )}
    </StyledCard>
  );
};
