export const formatValue = (value: number): string => {
  return new Intl.NumberFormat('pt-BR', { minimumFractionDigits: 0, maximumFractionDigits: 0 }).format(value);
};

export const formatValueToBRL = (value: number): string => {
  return value.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL', minimumFractionDigits: 0, maximumFractionDigits: 0 });
}

export const sumAndFormatValuesToBRL = (data: { month: string; value: number }[]): string =>{
  const total = data.reduce((acc, curr) => acc + curr.value, 0);
  return formatValueToBRL(total);
}


export const breakWords = (name: string, maxLineLength: number = 12) => {
  const words = name.split(' ');
  let result = '';
  let line = '';

  words.forEach((word: string) => {
    if ((line + word).length > maxLineLength) {
      result += line + '\n';
      line = word + ' ';
    } else {
      line += word + ' ';
    }
  });

  result += line;
  return result.trim();
}