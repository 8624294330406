// assets
import QueryStatsIcon from '@mui/icons-material/QueryStats';
import LocalAtmIcon from '@mui/icons-material/LocalAtm';
import { INavGroup } from '../models/Menu';
import { ROUTES } from '../routes/routes';

// ==============================|| MENU ITEMS - REPORT ||============================== //

export const ReportRoutes: INavGroup = {
  id: 'group-report',
  title: 'Relatórios',
  icon: <QueryStatsIcon />,
  type: 'group',
  module: 'REPORT',
  level: 1,
  children: [
    {
      id: 'financial_report',
      title: 'Relatório de Faturamento',
      type: 'item',
      url: ROUTES.authenticated.report.financial,
      icon: <LocalAtmIcon />,
      breadcrumbs: true,
      submodule: 'FINANCIAL_REPORT'
    },
  ]
};
