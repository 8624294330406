import { HelmetMeta } from '../../../layout/HelmetMeta';
import { ReportPage } from '../../../sections/@financial-report';

const ReportPageWrapper = () => (
  <>
    <HelmetMeta pageName="Relatórios" />
    <ReportPage />
  </>
);

export default ReportPageWrapper;
